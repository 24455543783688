import * as Klaro from 'klaro';
// import { isBot } from "../../react/utils";

declare global {
    interface Window {
        loadHotjar: () => void;
    }
}

export default {
    init() {
        // monitors if a page view has already been pushed
        let pageViewPushed = false;
        const cookieBannerInitialized = false;

        const config = {
            elementID: 'cookie-banner',
            storageMethod: 'cookie',
            storageName: 'klaro-cookie',
            mustConsent: false, // Cookie banner is a Modal
            acceptAll: true,
            hideDeclineAll: false,
            translations: {
                de: {
                    privacyPolicyUrl: 'http://glacier.eco/datenschutz',
                    consentNotice: {
                        description:
                            'Wir nutzen Cookies, um dir bestmögliche Funktionalitäten zu bieten. Mehr Infos dazu findest du hier: {privacyPolicy}',
                        learnMore: 'Einstellungen'
                    },
                    consentModal: {
                        title: 'Cookie Einstellungen',
                        description:
                            'Wir möchten Ihre Daten für folgende Zwecke verwenden dürfen. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Datenschutzerklärung (Name)'
                        }
                    },
                    ok: 'Alle akzeptieren',
                    decline: 'Funktionale Cookies',
                    acceptSelected: 'Speichern',
                    save: 'Speichern',
                    close: 'Schließen',
                    acceptAll: 'Alle akzeptieren',
                    purposes: {
                        essential: 'Essentiell',
                        functional: 'Funktional',
                        analytics: 'Analyse & Statistik',
                        marketing: 'Marketing'
                    }
                },
                en: {
                    privacyPolicyUrl:
                        'http://glacier.eco/en/data-protection-policy',
                    consentNotice: {
                        description:
                            'We use cookies to offer you the best possible functionality. You can find more information here: {privacyPolicy}',
                        learnMore: 'Settings'
                    },
                    consentModal: {
                        title: 'Cookie settings',
                        description:
                            'We use cookies to offer you the best possible functionality. ',
                        privacyPolicy: {
                            text: 'Dies ist der Text mit einem Link zu Ihrer {privacyPolicy}.',
                            name: 'Privacy Policy (Name)'
                        }
                    },
                    ok: 'Accept all',
                    decline: 'Functional cookies',
                    acceptSelected: 'Save selection',
                    save: 'Save',
                    close: 'Close',
                    acceptAll: 'Accept all',
                    purposes: {
                        essential: 'essential',
                        functional: 'functional',
                        analytics: 'analytics & statistic',
                        marketing: 'marketing'
                    }
                }
            },
            services: [
                {
                    name: 'functional',
                    title: 'Craft CMS',
                    default: true,
                    purposes: ['functional'],
                    cookies: [
                        ['CraftSessionId'],
                        ['*_identity'],
                        ['*_username'],
                        ['CRAFT_CSRF_TOKEN']
                    ],
                    required: true
                },
                {
                    name: 'gtm',
                    title: 'Google Tag Manager',
                    purposes: ['analytics'],
                    cookies: [
                        [/^_ga.*$/i, '/', '.glacier.eco'],
                        ['_gid', '/', '.glacier.eco'],
                        [/^_dc_gtm.*$/i, '/', '.glacier.eco'],
                        [/^_gtm.*$/i, '/', '.glacier.eco']
                    ],
                    required: false
                },
                {
                    name: 'google-analytics',
                    title: 'Google Analytics',
                    purposes: ['analytics'],
                    cookies: [[/^_ga.*$/i], ['_gid']],
                    required: false
                },
                {
                    name: 'dealfront',
                    title: 'Dealfront',
                    purposes: ['analytics'],
                    cookies: [
                        [/^_lfa*$/i, '/', '.glacier.eco'],
                        ['_lfa', '/', '.glacier.eco'],
                        ['_lfa_consent', '/', '.glacier.eco'],
                        ['_lfa_test_cookie_stored', '/', '.glacier.eco'],
                        ['_lfa_expiry', '/', '.glacier.eco']
                    ],
                    required: false
                },
                {
                    name: 'hotjar',
                    title: 'Hotjar Analytics',
                    purposes: ['analytics'],
                    cookies: [],
                    required: false
                },
                {
                    name: 'linkedin',
                    title: 'LinkedIn',
                    purposes: ['analytics'],
                    cookies: [
                        ['AnalyticsSyncHistory', '/', '.linkedin.com'],
                        ['UserMatchHistory', '/', '.linkedin.com'],
                        ['bcookie', '/', '.linkedin.com'],
                        ['lang', '/', '.linkedin.com'],
                        ['li_gc', '/', '.linkedin.com'],
                        ['li_mc', '/', '.linkedin.com'],
                        ['liap', '/', '.linkedin.com'],
                        ['lidc', '/', '.linkedin.com'],
                        ['lms_ads', '/', '.linkedin.com'],
                        ['lms_analytics', '/', '.linkedin.com'],
                        ['lang', '/', '.linkedin.com'],
                        ['JSESSIONID', '/', '.linkedin.com'],
                        ['bscookie', '/', '.linkedin.com'],
                        ['li_alerts', '/', '.linkedin.com'],
                        ['li_at', '/', '.linkedin.com'],
                        ['li_rm', '/', '.linkedin.com']
                    ],
                    required: false
                },
                {
                    name: 'hubspot',
                    title: 'HubSpot',
                    purposes: ['analytics'],
                    cookies: [
                        ['__hstc', '/', '.glacier.eco'],
                        ['hubspotutk', '/', '.glacier.eco'],
                        ['__hssc', '/', '.glacier.eco'],
                        ['__hssrc', '/', '.glacier.eco']
                    ],
                    required: false
                },
                {
                    name: 'marketing',
                    title: 'Facebook Pixel',
                    purposes: ['marketing'],
                    cookies: [],
                    required: false
                }
            ],
            callback: function (consent: string, service: { name: string }) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const w = window as any;
                w.dataLayer = w.dataLayer || [];
                w.dataLayer.push({
                    event: 'cookie_consent_' + service.name + '_' + consent
                });

                // track pageview only when user already interacted with cookie banner - push only once per page
                if (!pageViewPushed && cookieBannerInitialized !== false) {
                    pageViewPushed = true;
                }

                // eslint-disable-next-line no-console
                console.log(
                    'User consent for service ' +
                        service.name +
                        ': consent=' +
                        consent
                );

                // only load hotjar script tag when user accepted it (function in base-web-layout.twig file)
                if (service.name === 'hotjar' && consent) {
                    window.loadHotjar();
                }
            }
        };

        // we assign the Klaro module to the window, so that we can access it in JS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const w = window as any;
        w.klaro = {};
        w.klaro = Klaro;
        w.klaroConfig = config;
        // we set up Klaro with the config
        Klaro.setup(config);
    }
};
